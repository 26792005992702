import React, { useState, useEffect, useRef } from "react";
import { Scoped } from "kremling";
import { catchAsyncStacktrace } from "auto-trace";
import { toastsStream } from "../toast-stream.js";
import { useOnce } from "use-once";
import { listCss as css } from "./toast-css.js";
import Toast from "./toast/toast.component.js";

export default function ToastList() {
  const [activeToasts, setActiveToasts] = useState([]);
  const timeoutId = useRef(null);
  const toastsToRender = activeToasts.slice(0, 3).reverse();

  useToastStream(setActiveToasts);
  useEffect(toastTimeouts, [toastsToRender]);

  useEffect(() => {
    const closeListener = (e) => closeToast(e.detail.toast.id);
    window.addEventListener("toast:close", closeListener);
    return () => window.removeEventListener("toast:close", closeListener);
  }, []);

  return (
    <Scoped css={css}>
      <div
        className="all-toasts"
        onMouseOver={clearCurrentTimeout}
        onMouseLeave={toastTimeouts}
      >
        {toastsToRender.map((toast, index) => {
          let position = "bottom";
          const length = toastsToRender.length;
          if (length - 1 === index) {
            position = "top";
          } else if (length - 2 === index) {
            position = "middle";
          }
          return (
            <Toast
              key={toast.id}
              toast={toast}
              position={position}
              onBlur={toastTimeouts}
              onFocus={clearCurrentTimeout}
              close={closeToast}
            />
          );
        })}
      </div>
    </Scoped>
  );

  function toastTimeouts() {
    clearTimeout(timeoutId.current);

    if (toastsToRender.length > 0) {
      const nextToastToRemove = toastsToRender[toastsToRender.length - 1];
      timeoutId.current = setTimeout(() => {
        closeToast(nextToastToRemove.id);
      }, nextToastToRemove.durationInMillis);
    }
  }

  function closeToast(toastId) {
    setActiveToasts((toasts) => toasts.filter((toast) => toast.id !== toastId));
  }

  function clearCurrentTimeout() {
    clearTimeout(timeoutId.current);
  }
}

function useToastStream(setActiveToasts) {
  useOnce(() => {
    const subscription = toastsStream.subscribe((newToast) => {
      // The oldest toast is rendered last (bottom of dom) which makes it visible
      setActiveToasts((prevToasts) => [...prevToasts, newToast]);
    }, catchAsyncStacktrace());

    return () => {
      subscription.unsubscribe();
    };
  });
}
